<template>
  <div class="py-6 px-4">
    <div class="d-flex justify-space-between align-center">
      <v-btn :to="{ name: 'Login' }" text class="text-none primary--text">
        <span class="material-symbols-rounded icon"> arrow_back </span>
        <span class="ml-2 p">Volver al Login</span>
      </v-btn>
      <v-img class="global-logoimg" src="@/assets/logo-mclick-min.png">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-container>
      <v-row>
        <v-col class="text-center" cols="12">
          <span class="h3 semiBold">Recuperar contraseña</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-card outlined elevation="0" class="px-6">
            <v-card-text>
              <div class="py-4 text-center">
                <span class="h4"
                  >Ingresa tu <span class="semiBold">Rut</span> y sigue las
                  instrucciones para recuperar tu contraseña</span
                >
              </div>
              <div>
                <v-form ref="recoverpass">
                  <LabelComponent text="Rut" />
                  <Input
                    size="long"
                    color="#0077c8"
                    :model="rut"
                    name="rut"
                    :hide="false"
                    readonly="false"
                    :rules="[rules.required, rules.isValid]"
                    @input="rut = $event"
                    max_length="12"
                    :rut="true"
                  />
                </v-form>
                <div
                  v-if="snackbar"
                  class="global-errormsg red--text text-left"
                >
                  {{ errormsg }}
                </div>
              </div>
              <div class="text-center my-6">
                <v-btn
                  color="primary"
                  @click.native="recover()"
                  rounded
                  width="160"
                  class="text-none"
                  :loading="loading"
                  >Continuar</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Modal
      :open="openthree"
      @close="closeDialogThree"
      :user-email="useremail"
      :type="0"
    />
  </div>
</template>
<script>
import Input from "@/components/Inputs/Input.vue";
import Modal from "@/components/Modal/Modal-Change-Password";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  components: {
    Input,
    Modal,
    LabelComponent,
  },
  data() {
    return {
      rut: "",
      loading: false,
      openthree: false,
      snackbar: null,
      errormsg: "",
      useremail: "",
      rules: {
        required: (value) => !!value || "El RUT es requerido.",
        isValid(value) {
          var valor = value.replace(/[^A-Z0-9]/gi, "");

          var cuerpo = valor.slice(0, -1);
          var dv = valor.slice(-1).toUpperCase();

          var suma = 0;
          var multiplo = 2;

          for (var i = 1; i <= cuerpo.length; i++) {
            var index = multiplo * valor.charAt(cuerpo.length - i);

            suma = suma + index;

            if (multiplo < 7) {
              multiplo = multiplo + 1;
            } else {
              multiplo = 2;
            }
          }

          var dvEsperado = 11 - (suma % 11);

          // Casos Especiales (0 y K)
          dv = dv == "K" ? 10 : dv;
          dv = dv == 0 ? 11 : dv;

          // Validar que el Cuerpo coincide con su Dígito Verificador

          if (dvEsperado != dv) {
            return "Rut inválido";
          } else {
            return true;
          }
        },
      },
    };
  },
  methods: {
    recover() {
      if (this.$refs.recoverpass.validate() && this.rut) {
        this.loading = true;
        let payload = {
          usuario: this.rut.replace(/[^A-Z0-9]/gi, ""),
          convenio: this.$store.getters.url,
          modulo: "venta",
        };
        this.$store
          .dispatch("passwordRecovery", payload)
          .then((response) => {
            this.useremail = response.correo;
            this.openthree = true;
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading = false));
      }
    },
    closeDialogThree() {
      this.openthree = false;
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.icon {
  font-size: 15px;
}
.h3 {
  color: #223a65 !important;
}
.v-btn__content {
  letter-spacing: 0.1px !important;
}
.primary--text {
  color: #0077c8 !important;
}
</style>
