<template name="component-name">
  <v-text-field
    v-if="!rut"
    :label="label"
    :placeholder="placeholder"
    :hide-details="hide"
    height="30"
    dense
    outlined
    :value="model"
    :background-color="readonly === 'true' ? '#F2F2F2' : 'none'"
    :disabled="readonly === 'true' ? true : false"
    :prepend-inner-icon="icon"
    :rules="rules"
    :maxlength="max_length"
    :type="type"
    :prefix="price ? '$' : ''"
    :suffix="weight ? 'Kgs' : ''"
    :error-messages="error"
    @input="handlerInput"
    @blur="handleBlur"
  ></v-text-field>
  <v-text-field
    dense
    v-else
    :label="label"
    :placeholder="placeholder"
    :hide-details="hide"
    height="30"
    outlined
    :value="model"
    :background-color="readonly === 'true' ? '#F2F2F2' : 'none'"
    :disabled="readonly === 'true' ? true : false"
    :prepend-inner-icon="icon"
    :rules="rules"
    :type="type"
    :maxlength="max_length"
    :error-messages="error"
    @input="handlerInput"
    v-mask="rutMask"
  ></v-text-field>
</template>
<script>
import { mapGetters } from "vuex";
import { rutMask } from "@/helpers/mask.js";
export default {
  name: "inputText",
  props: {
    label: String,
    placeholder: String,
    model: String,
    max_length: [String, Number],
    color: String,
    detail_color: String,
    readonly: [String, Boolean],
    hide: [String, Boolean],
    icon: String,
    rut: [String, Boolean],
    type: String,
    weight: String,
    price: String,
    error: {
      type: String,
      default: "",
    },
    rules: Array,
  },
  data: () => ({
    inputVal: "",
  }),
  computed: {
    ...mapGetters("Users", ["getBotonColor"]),

    btnColor() {
      return this.getBotonColor;
    },
    rutMask: () => rutMask,
  },
  methods: {
    handlerInput(val) {
      this.$emit("input", val);
    },
    handleBlur(val) {
      this.$emit("blur", val);
    },
  },
};
</script>
