<template>
  <div class="text-center">
    <v-dialog v-model="open" @click:outside="$emit('close')" width="40%">
      <v-card class="py-4">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="h5 semiBold title-color">¡Revisa tu correo!</span>
            <v-btn icon @click="$emit('close')">
              <span class="material-symbols-rounded icon"> close </span>
            </v-btn>
          </div>
          <div class="d-flex align-center my-8" v-if="type == 0">
            <span class="material-symbols-rounded icon" style="font-size: 50px">
              mail
            </span>
            <span class="ml-4 p">
              Te enviamos un correo a
              <span class="semiBold">{{ userEmail }}</span> con las
              instrucciones para que recuperes tu contraseña
            </span>
          </div>
          <v-flex
            class="text-left pl-6 pt-10 mt-10 ml-10"
            v-else-if="type == 1"
          >
            <h1 class="global-titleStyle9">
              su contrasena ha sido cambiada con exito!
            </h1>

            <h4 class="mt-12 global-subtitleStyle5">
              Favor de ingresar nuevamente al sistema <br />
              con la contrasena nueva
            </h4>
          </v-flex>
          <div class="text-center mt-4">
            <v-btn
              @click="$emit('close')"
              rounded
              class="text-none"
              color="primary"
              width="170"
              >Entendido</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["type", "open", "userEmail"],
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
